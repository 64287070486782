import {
  AdvancedFilterModel,
  DateAdvancedFilterModel,
  DateStringAdvancedFilterModel,
  FilterModel,
  NumberAdvancedFilterModel,
  ObjectAdvancedFilterModel,
  SortModelItem,
  TextAdvancedFilterModel,
} from '@ag-grid-community/core';

const filterTypesMap = {
  contains: 'substring',
  equals: 'eq',
  In: 'in',
  notEqual: 'ne',
  endsWith: 'endsWith',
  startsWith: 'startsWith',
  lessThanOrEqual: 'lte',
  lessThan: 'lt',
  greaterThanOrEqual: 'gte',
  greaterThan: 'gt',
  Includes: 'contains',
  Like: 'like',
};

export type FilterModelType = AdvancedFilterModel | FilterModel | null;

export type ExcludeBooleanFilterModelType =
  | TextAdvancedFilterModel
  | NumberAdvancedFilterModel
  | DateAdvancedFilterModel
  | DateStringAdvancedFilterModel
  | ObjectAdvancedFilterModel;

export type SortModelType = SortModelItem[];

const handleStringType = (key: string, value: string) => [`filter[${key}][eq]`, value];

const handleNumberOrBooleanType = (key: string, value: number | boolean) => [key, value];

const handleSortModelType = (key: string, value: SortModelItem) => [
  `sort[${value.sort}]`,
  value.colId,
];

const handleArrayType = (key: string, value: string[]) => [`filter[${key}][in]`, value];

const handleBooleanAdvancedFilterModel = (key: string, value: boolean) => [
  `filter[${key}][eq]`,
  value,
];

const handleSearchModel = (
  key: string,
  value: ExcludeBooleanFilterModelType,
  inOperatorMultifilter: any
) => {
  const fmodel = value;
  const mappedType = filterTypesMap[fmodel?.type as keyof typeof filterTypesMap];

  if (!mappedType) {
    return [];
  }

  if (
    fmodel &&
    fmodel.filterType === 'object' &&
    'filter' in fmodel &&
    typeof fmodel.filter === 'string'
  ) {
    if (key !== 'id') {
      return [`filter[${key}][contains][0]`, fmodel?.filter];
    }
    const values = fmodel.filter.split(',');
    const additionalValues = values.splice(1);
    for (let x = 0; x < additionalValues.length; x++) {
      inOperatorMultifilter[`filter[${key}][in][${x + 1}]`] = additionalValues[x].trim();
    }
    return [`filter[${key}][in][0]`, values[0].trim()];
  }

  return [`filter[${key}][${mappedType}]`, fmodel?.filter];
};

export const filterParamsToQueryParams = (
  filterParams: Record<
    string,
    string | number | boolean | string[] | FilterModelType | SortModelType | undefined
  > = {}
) => {
  let inOperatorMultifilter: any = {};
  let duplicateFilterParams = { ...filterParams };
  if (duplicateFilterParams.conditions && Array.isArray(duplicateFilterParams.conditions)) {
    duplicateFilterParams.conditions?.forEach((condition: any) => {
      if (condition.colId) {
        duplicateFilterParams[condition.colId as string] = {
          filterType: condition.filterType,
          type: condition.type,
          filter: condition.filter,
        };
      }
    });
    delete duplicateFilterParams.conditions;
    delete duplicateFilterParams.filterType;
    delete duplicateFilterParams.type;
  }

  if (duplicateFilterParams.colId) {
    duplicateFilterParams[duplicateFilterParams.colId as string] = {
      filterType: duplicateFilterParams.filterType,
      type: duplicateFilterParams.type,
      filter: duplicateFilterParams.filter,
    };
    delete duplicateFilterParams.colId;
    delete duplicateFilterParams.filterType;
    delete duplicateFilterParams.type;
    delete duplicateFilterParams.filter;
  }
  return {
    ...Object.fromEntries(
      Object.entries(duplicateFilterParams).map(([key, value]) => {
        if (typeof value === 'string') {
          return handleStringType(key, value);
        }
        //handles paging
        if (typeof value === 'number' || typeof value === 'boolean') {
          return handleNumberOrBooleanType(key, value);
        }
        //handles sorting
        if (value && typeof value === 'object' && 'sort' in value && 'colId' in value) {
          return handleSortModelType(key, value as SortModelItem); // Update the type of value to SortModelItem
        }
        if (Array.isArray(value)) {
          return handleArrayType(key, value as string[]); // Update the type of value to string[]
        }
        if (value && typeof value === 'object' && value.filterType === 'boolean') {
          return handleBooleanAdvancedFilterModel(key, value.type as boolean);
        }
        // assumes that this is a search model
        if (value && typeof value === 'object' && 'filter' in value) {
          return handleSearchModel(
            key,
            value as ExcludeBooleanFilterModelType,
            inOperatorMultifilter
          );
        }

        return [`filter[${key}]`, value];
      })
    ),
    ...inOperatorMultifilter,
  };
};
